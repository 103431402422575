





























import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { IUserProfile } from '@/interfaces';
import {
    dispatchRunUpdateBrandProducts,
    dispatchRunUpdateBrandWidget,
    dispatchRunUpdateBrandPartners,
    dispatchRunPublishBrandWidget,
    dispatchRunUpdateBrandStatistics,
} from '@/store/admin/actions';

import { ttt } from '@/nutils';

@Component
export default class PruebaData extends Vue {
  public ttt = ttt;

  public async upd_products() {
    await dispatchRunUpdateBrandProducts(this.$store, 'all');
    this.$router.push('/main/admin/admindashboard');
  }

  public async upd_widget() {
    await dispatchRunUpdateBrandWidget(this.$store, 'all');
    this.$router.push('/main/admin/admindashboard');
  }

  public async upd_partners() {
    await dispatchRunUpdateBrandPartners(this.$store, 'all');
    this.$router.push('/main/admin/admindashboard');
  }

  public async publish_widget() {
    await dispatchRunPublishBrandWidget(this.$store, 'all');
    this.$router.push('/main/admin/admindashboard');
  }

  public async upd_statistics() {
    await dispatchRunUpdateBrandStatistics(this.$store, 'all');
    this.$router.push('/main/admin/admindashboard');
  }

  public cancel() {
    this.$router.back();
  }
}
